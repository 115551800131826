.body {
  font-family: $font-family-sans-serif;
  background-color: #f8f8fb;
  height: 100%;
  min-height: calc(100vh - 104px);
  // max-height: calc(100% - 100px);
}

.disable-div{
  color: #ccc;
  opacity: 0.6;
  pointer-events: none;
}

.card-company-table {
  margin-bottom: 30px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  border: 0 !important;
}

.share-link-main {
  background-color: #e7e7e78f;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.share-link-icon {
  padding: 20px;
  font-size: 40px;
}

html {
  overflow-x: auto;
}

.gridTopLabel {
  padding-left: 2%;
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #767676;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #767676;
}

.cloud-container {
  width: 100%;
  padding: 0 80px;
  // margin-right: auto;
  // margin-left: auto;

  @media (max-width: 991px) {
    padding: 0 60px;
  }
  @media (max-width: 767px) {
    padding: 0 40px;
  }
}
