.breadCrumb {
  font-family: $font-family-sans-serif;
  justify-content: space-between;
  display: flex;
}
.breadCrumb a,
.breadCrumbItem,
.breadCrumbHome {
  font-size: 15px;
  text-decoration: none;
  color: #767676;
  font-weight: 500;
}
.breadCrumb a:hover,
.breadCrumbItem:hover,
.breadCrumbHome:hover {
  color: #222222cf;
  cursor: pointer;
}
.breadCrumbSelectedItem {
  color: #222222cf;
  font-weight: 610;
}
.fileListViewIcon {
  margin-top: 8px;
  width: 45px;
  padding: 20px ;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3f4254;
  background-color: #f5f8fa;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.475rem;
  cursor: pointer;
}
.fileListViewIcon i,
svg {
  cursor: pointer;
  color: #767676;
  fill: #767676;
}
.fileListViewIcon i:hover,
svg:hover {
  color: #556ee6;
  fill: #556ee6;
}
.fileListViewIcon:hover {
  transition: all 0.5s;
}
.search {
  position: relative;
 
  
  @media only screen and (max-width: 576px) {
    padding-right: 0;
  }
}
.search-field {
  font-size: 15px;
  border: 1px solid #bbc6cd;
  border-radius: 10px;
  background-color: #f5f8fa;
  padding: 8px 35px;
  color: #555b6d;

  @media only screen and (max-width: 576px) {
    width: 155px;
  }
}
.search-field:focus {
  outline: none;
}
.search-icon {
  position: absolute;
  line-height: 25px;
  left: 12px;
  top: 9px;
  transform: translateY(-10%);
  height: 16px;
  max-width: 16px;
  font-size: 14px;
  color: #767676;
}

.main-left-breadcrumb {
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 768px) {
    justify-content: space-between;
  }
}

.breadcrumbLoadingIcon{
  margin-left: 10px;
}
