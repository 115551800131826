//
// Google font - Poppins
//

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

//  Custom Fonts

@font-face {
  font-family: roboto-regular;
  src: url("../../../fonts/text-fonts/Roboto-Regular.ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: robotocondensed-regular;
  src: url("../../../fonts/text-fonts/RobotoCondensed-Regular.ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: andale mono;
  src: url("../../../fonts/text-fonts/andale-mono.ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: andalemono;
  src: url("../../../fonts/text-fonts/andale-mono.ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: arial black;
  src: url("../../../fonts/text-fonts/arial-black.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: arialblack;
  src: url("../../../fonts/text-fonts/arial-black.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: book antiqua;
  src: url("../../../fonts/text-fonts/BKANT.TTF");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: bookantiqua;
  src: url("../../../fonts/text-fonts/BKANT.TTF");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: comic sans ms;
  src: url("../../../fonts/text-fonts/comic.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: comicsansms;
  src: url("../../../fonts/text-fonts/comic.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: georgia;
  src: url("../../../fonts/text-fonts/Georgia.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: helvetica;
  src: url("../../../fonts/text-fonts/Helvetica.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: impact;
  src: url("../../../fonts/text-fonts/impact.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: symbol;
  src: url("../../../fonts/text-fonts/Symbol_Regular.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: tahoma;
  src: url("../../../fonts/text-fonts/Tahoma\ Regular\ font.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: terminal;
  src: url("../../../fonts/text-fonts/Terminal.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: times new roman;
  src: url("../../../fonts/text-fonts/times\ new\ roman.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: timesnewroman;
  src: url("../../../fonts/text-fonts/times\ new\ roman.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: trebuchet ms;
  src: url("../../../fonts/text-fonts/trebuc.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: trebuchetms;
  src: url("../../../fonts/text-fonts/trebuc.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: verdana;
  src: url("../../../fonts/text-fonts/verdana.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: calibri;
  src: url("../../../fonts/text-fonts/Calibri.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: rockwell;
  src: url("../../../fonts/text-fonts/ROCK.TTF");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: garamond;
  src: url("../../../fonts/text-fonts/Garamond.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: franklingothic;
  src: url("../../../fonts/text-fonts/FRANKGO.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: cambria;
  src: url("../../../fonts/text-fonts/Cambria.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: futura;
  src: url("../../../fonts/text-fonts/FuturaLightfont.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: baskerville;
  src: url("../../../fonts/text-fonts/baskvl.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: gotham;
  src: url("../../../fonts/text-fonts/GothamLight.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: bodoni;
  src: url("../../../fonts/text-fonts/bodoni.ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: myriad;
  src: url("../../../fonts/text-fonts/Myriad.ttf");
  font-weight: normal;
  font-display: swap;
}
