.table {
  font-family: $font-family-sans-serif;

  td {
    vertical-align: middle;
  }
  .folderName {
    cursor: pointer;
  }
  .symbol {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.475rem;

    &:hover {
      svg {
        &:hover {
          color: $sidebar-menu-item-active-color;
        }
      }
    }
  }
  .symbol-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #3f4254;
    background-color: #f5f8fa;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.475rem;
  }
  .symbol-dark-bg{
    background-color: #f1f1f1;
  }
  .symbol-label {
    width: 45px;
    height: 45px;
  }

  .symbol-label:hover {
    transition: all 0.5s;
    cursor: pointer;
  }

  .download-icon {
    transform: rotate(90deg);
  }

  .icon-size-list {
    height: 30px;
    width: 30px;
  }

  .icon-size-grid {
    height: 100px;
    width: 100px;
  }

  .grid-type-icon {
    position: relative;
    top: 60px;
  }
  .grid-download-icon {
    position: relative;
    top: -217px;
  }
  .grid-modification-text {
    position: relative;
    bottom: -50px;
    left: 50px;
  }
  .grid-size-text {
    bottom: -80px;
    position: relative;
    left: -60px;
  }
  .grid-folder-text {
    position: relative;
    top: 60px;
  }
}
.selection-cell-header {
  width: 2%;
}
.selection-cell {
  vertical-align: bottom !important;
}
.sortable {
  min-width: 290px;
  width: 70%;
}
.size {
  min-width: 120px
}
.modified{
  min-width: 180px;
}
.tableTitle{
  max-width: 700px;
}
.downArrowSort {
  position: relative;
  right: 10px;
}
.nameSort {
  color: #aaa;
}
.loading i,
.loading span {
  font-size: 18px;
}
.loaderImage {
  width: 100%;
  // height: 67vh;
  height: calc(100vh - 390px);
}
.loaderImage td {
  border: none;
}
.loaderImage img {
  width: 10%;
}

.grid-scoreCard {
  $grid-size: 120px;
  $grid-pad: 10px;
  $name-height: 30px;
  display: flex;
  flex-direction: column;
  tbody {
    display: flex;
    grid-template-columns: repeat(auto-fill, $grid-size);
    tr {
      min-width: 230px;

      position: relative;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      width: 100px;
      border: 1px solid #e6e6e6;
      margin-right: 139%;
      margin-top: 20px;
      margin-left: 3px;
      td {
        padding: 4px 0px 4px 0px;

        border: none;
        // padding: 0;
        text-align: center;
        border-radius: var(--border-radius);
        &.filename {
          padding: #{$grid-size - 2 * $grid-pad} 0 0 0;
          background-position: center top;
          background-size: contain;
          line-height: $name-height;
          max-width: none;
          .filename-parts {
            justify-content: center;
          }
        }
        &.filesize {
          line-height: $name-height / 3;
          width: 100%;
        }
        &.date {
          display: none;
        }
      }
    }
  }
}

.table > :not(:first-child) {
  border-top: none !important;
}

.react-bootstrap-table-pagination {
  .react-bs-table-sizePerPage-dropdown {
    visibility: hidden !important;
  }
  .pagination {
    float: right;

    a {
      border: 0;
      border-radius: 0.25rem;
      color: $sidebar-menu-item-active-color;
    }
    .page-item.active .page-link {
      background-color: $sidebar-menu-item-active-color;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

input[type="checkbox" i] {
  cursor: pointer;

  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
}
.preview {
  position: relative;
}

.gallery-close {
  position: absolute;
  // top: 7px;
  // right: 15px;
}

.carousel-title {
  position: absolute;
  bottom: 7rem;
  display: flex;
}

.file-handler-preview {
  // position: relative;
  display: flex;
  justify-content: space-between;

  left: auto;
  right: 0px;
}

.carousel-toolbar {
  position: absolute;
  bottom: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
}

.gallery-div > div {
  z-index: 9999;
}

.media-gallery .gallery-close {
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.gallery-carousel {
  z-index: 1000;
  top: 0px;
  left: 0px;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
}

.gallery-carousel .carousel .control-prev.control-arrow:before {
  border-right: 18px solid #fff;
}

.gallery-carousel .carousel .control-next.control-arrow:before {
  border-left: 18px solid #fff;
}

.gallery-carousel .carousel .control-arrow:before,
.gallery-carousel .carousel.carousel-slider .control-arrow:before {
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}

.gallery-carousel .carousel .control-prev.control-arrow {
  left: -50px;
}

.gallery-carousel .carousel .control-next.control-arrow {
  right: -50px;
}

.gallery-carousel .carousel.carousel-slider {
  overflow: unset;
}

.gallery-carousel .control-dots {
  display: none !important;
}

// *** table
.cursor-pointer {
  cursor: pointer;
}

.prev-btn {
  position: absolute;
  top: 50%;
  left: 15px;
  border: 0;
  height: 50px;
  width: 50px;
  background: rgb(243, 236, 236);
  color: rgb(63, 55, 55);
  font-size: 20px;
  padding: 5px;
  border-radius: 50%;
}

.next-btn {
  position: absolute;
  top: 50%;
  right: 15px;
  border: 0;
  height: 50px;
  width: 50px;
  background: rgb(243, 236, 236);
  color: rgb(63, 55, 55);
  font-size: 20px;
  padding: 5px;
  border-radius: 50%;
}

.close-btn {
  top: 50%;
  left: 20px;
  border: 0;
  height: 40px;
  width: 40px;
  background: white;
  color: rgb(63, 55, 55);
  font-size: 20px;
  padding: 3px;
  margin-right: 10px;
  border-radius: 50%;
}

.btn-close {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-right: 20px;
  // right: 15px;
  border: 0;
  height: 40px;
  width: 40px;
  background: rgb(243, 236, 236);
  color: rgb(63, 55, 55);
  font-size: 25px;
  padding: 3px;
  border-radius: 50%;
  // right: 15px;
}

.preview-tab {
  height: 109px;
  width: 632px;
  background-color: rgb(232, 237, 238);
  /* top: 55px; */
  bottom: 30px;
  position: fixed;
  // opacity: 0.4;
  // &:hover{
  //   opacity: 1;
  // }
}

.filename {
  font-size: 20px;
}

.preview-tab span {
  padding-right: 10px;
  padding-left: 10px;
}

.preview-row-1 {
  border-bottom: 1px solid;
  border-color: inherit;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.preview-1:hover {
  display: block;
}

.data-table {
  .react-bootstrap-table {
    overflow: auto;
  }
}

.data-center {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card {
  box-shadow: 0 0 10px 0px #12263f2b !important;
  border: 0 !important;
}

.card-icon {
  height: 100px;
  width: 100px;

  &:hover {
    transform: scale(1.1);
  }
}

.chb {
  visibility: hidden;
  + {
    label {
      padding-left: 5px;
    }
  }
}
.chb-3 {
  + {
    label {
      &::before {
        cursor: pointer;
        position: absolute;
        left: 0px;
        top: 4px;
        border: 2px solid var(--bs-gray-600);
        content: " ";
        width: 16px;
        height: 16px;
        transition: all 0.1s ease-in, border-color 0.05s ease-in;
      }
    }
  }
  &:checked {
    + {
      label {
        &::before {
          transform: rotate(40deg);
          border-top-color: transparent;
          border-left-color: transparent;
          top: 2px;
          left: 0px;
          width: 9px;
          height: 18px;
          border-bottom-color: green;
          border-right-color: green;
          border-bottom-width: 4px;
        }
      }
    }
  }
}

.card-text {
  font-weight: 500;
}

.setPositionDownloadGrid {
  top: -15px;
}

.nameColumn {
  display: flex;
  align-items: center;
  position: relative;
  left: 50px;
}

.tableSelectAllBox{
  top: 13px;
}

.downloadColumn{
  text-align: right;
}
.tableDownloadLoading{
  margin: 0px 12px 0px 0px;
}
.syncing {
  animation: spin 2s linear infinite;
}