.urlDownload {
    * {
        box-sizing: border-box;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    .page {
        height: 100%;
        display: flex;
        align-items: center;
        -moz-box-align: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
    }

    .folder {
        background-color: #2196f3;
        position: relative;
        width: 92px;
        height: 64px;
        display: block;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .folder-tab {
        position: absolute;
        height: 10px;
        left: 0;
        bottom: 100%;
        display: block;
        width: 40%;
        border-top-left-radius: 8px;
        background-color: inherit;

        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: calc(100% - 10px);
            border-bottom: 10px solid #2196f3;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }
    }

    .folder-icn {
        padding-top: 5px;
        width: 100%;
        height: 100%;
        display: block;
    }

    .downloading {
        width: 30px;
        height: 32px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
    }

    .lds-ring {
        display: inline-block;
        position: relative;
        // width: 80px;
        // height: 80px;
        display: flex;
        justify-content: center;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        margin: 8px;
        border: 6px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      

    .custom-arrow {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -7px;
        background-color: #fff;

        -webkit-animation-name: downloading;
        -webkit-animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
        animation-name: downloading;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;

        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            left: -9px;
            border-top: 15px solid #fff;
            border-left: 16px solid transparent;
            border-right: 16px solid transparent;
        }
    }

    .bar {
        width: 30px;
        height: 4px;
        background-color: #fff;
        margin: 0 auto;
    }

    @-webkit-keyframes downloading {
        0% {
            top: 0;
            opacity: 1;
        }

        50% {
            top: 110%;
            opacity: 0;
        }

        52% {
            top: -110%;
            opacity: 0;
        }

        100% {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes downloading {
        0% {
            top: 0;
            opacity: 1;
        }

        50% {
            top: 110%;
            opacity: 0;
        }

        52% {
            top: -110%;
            opacity: 0;
        }

        100% {
            top: 0;
            opacity: 1;
        }
    }

   

    // Sample container
    .main-container {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    // Main CSS
    .check-container {
        width: 6.25rem;
        height: 7.5rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;

        .check-background {
            width: 100%;
            height: calc(100% - 1.25rem);
            background: linear-gradient(to bottom right, #5de593, #41d67c);
            box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
            transform: scale(0.84);
            border-radius: 50%;
            animation: animateContainer 0.75s ease-out forwards 0.75s;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;

            svg {
                width: 65%;
                transform: translateY(0.25rem);
                stroke-dasharray: 80;
                stroke-dashoffset: 80;
                animation: animateCheck 0.35s forwards 1.25s ease-out;
            }
        }

        .check-shadow {
            bottom: calc(-15% - 5px);
            left: 0;
            border-radius: 50%;
            background: radial-gradient(closest-side, rgba(73, 218, 131, 1), transparent);
            animation: animateShadow 0.75s ease-out forwards 0.75s;
        }
    }

    @keyframes animateContainer {
        0% {
            opacity: 0;
            transform: scale(0);
            box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }

        25% {
            opacity: 1;
            transform: scale(0.9);
            box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }

        43.75% {
            transform: scale(1.15);
            box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }

        62.5% {
            transform: scale(1);
            box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
        }

        81.25% {
            box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
        }

        100% {
            opacity: 1;
            box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
        }
    }

    @keyframes animateCheck {
        from {
            stroke-dashoffset: 80;
        }

        to {
            stroke-dashoffset: 0;
        }
    }

    @keyframes animateShadow {
        0% {
            opacity: 0;
            width: 100%;
            height: 15%;
        }

        25% {
            opacity: 0.25;
        }

        43.75% {
            width: 40%;
            height: 7%;
            opacity: 0.35;
        }

        100% {
            width: 85%;
            height: 15%;
            opacity: 0.25;
        }
    }

    svg{
        fill: #5de593;
    }

}
