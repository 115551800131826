.GrowCloud_Footer {
  font-family: $font-family-sans-serif;
  width: 100%;
  bottom: 0px;
  left: auto;
  background-color: #222;
}
.footerText {
  color: #888;
  font-weight: 400;
  font-size: 12px;
}
