.header {
  font-family: $font-family-sans-serif;
  background-color: #2d3335;
  color: #fff;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 5%);
  left: 250px;
  position: sticky;
  right: 0;
  top: 0;
  left: 0;
  z-index: 1002;
  height: 54px;
  display: flex;
  align-items: center;

  .head-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      align-items: center;
      display: inline-flex;
      flex: 1 0;
      white-space: nowrap;
      min-width: 90px;

      .nextcloud {

        .logo {
          width: 100%;
          height: auto;
          max-width: 50.72px;
        }

        .appname {
          color: #555b6d;
          font-size: 22px;
          font-weight: bold;
          margin: 0;
          padding: 0;
          padding-right: 6px;
          overflow: hidden;
          text-overflow: ellipsis;
          // flex: 1 1 100%;
        }
      }
    }

    .header-right {
      .header-right-web {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 1;

        .drive-btn {
          background-color: #f3f3f9;
          min-width: 60px;
          text-align: center;
          height: 30px;
          white-space: nowrap;
          //line-height: 28px;
          border-radius: 25px;
          cursor: pointer;
          display: flex;
          align-items: center;

          .btn-logo {
            width: 18px;
            height: 18px;
            margin: 0;
            padding: 0;
            display: inline-block;
            float: left;
            // margin-left: 4px;
            //margin-top: 6px;
            position: relative;
          }

          .btn-text {
            font-size: 11px;
            color: #555b6d;
            font-weight: bold;
            padding: 1px 10px;
          }
        }

        .drive-btn:hover {
          background-color: #ccc;
          color: #000;
          transition: all 0.5s;

          .btn-text {
            color: #000;
          }
          svg{
            fill: #000;
          }
        }

        .download {
          border-radius: 25px;
          margin-left: 10px;
          height: 28px;
          border: 1px solid #f3f3f9;
          padding: 6px 16px;
          background-color: #f3f3f9;

          .download-txt {
            font-weight: bold;
            color: #555b6d;
            font-size: 13px;
          }
        }

        .download:hover {
          background-color: #556ee6;
          transition: all 0.5s;

          .download-txt {
            color: white;
          }
        }

        .dropdown {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 100%;
          cursor: pointer;
          opacity: 0.6;
          padding: 0;
          margin: 0;
        }

        @media only screen and (max-width: 576px) {
          justify-content: flex-start;
        }
      }

      // .header-right-phone {
      //   display: none;
      //   @media only screen and (max-width: 576px) {
      //     display: block;
      //   }
      // }
    }
  }
}

/*** Page Not Found Start ***/
.bx-spin {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  color: #556ee6 !important;
}

.bx-spin-hover:hover {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.text-primary {
  color: $blue;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.page-content {
  min-height: 100vh;
}

/*** Page Not Found End ***/

.offcanvas {
  width: 80% !important;

  .offcanvas-body {
    ul {
      padding: 0;

      li {
        margin: 3px;

        .sidebar-logo {
          width: 25px;
        }
      }
    }
  }
}
