.preview {
  position: relative;
}

.gallery-close {
  position: absolute;
  // top: 7px;
  // right: 15px;
}

.carousel-title {
  position: absolute;
  bottom: 7rem;
  display: flex;
}

.file-handler-preview {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 0px;
  left: 0px;
  right: 0px;
}


.carousel-toolbar {
  position: absolute;
  bottom: 5rem;
  display: flex;
}

.gallery-div>div {
  z-index: 9999;
}

.media-gallery .gallery-close {
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}



.gallery-carousel {
  z-index: 1000;
  top: 0px;
  left: 0px;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
}

.gallery-carousel .carousel .control-prev.control-arrow:before {
  border-right: 18px solid #fff;
}

.gallery-carousel .carousel .control-next.control-arrow:before {
  border-left: 18px solid #fff;
}

.gallery-carousel .carousel .control-arrow:before, .gallery-carousel .carousel.carousel-slider .control-arrow:before {
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}

.gallery-carousel .carousel .control-prev.control-arrow {
  left: -50px;
}

.gallery-carousel .carousel .control-next.control-arrow {
  right: -50px;
}

.gallery-carousel .carousel.carousel-slider {
  overflow: unset;
}

.gallery-carousel .control-dots {
  display: none !important;
}

.imagePreview{
  max-height: 100vh;
  padding: 20px;
} 

// .pdfViewer{
//   min-width: calc( 100vw - 40px);
//   min-height: 92VH;

//   .pdf-embed{
//     min-height: 90vh;
//     max-height: calc( 100% - 1px );
//     // height: 100vh;
//     width: 100%;
//   }
// }


